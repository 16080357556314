
import React, { useState } from 'react';
import {
  FaCheck,
  FaStar,
  FaCertificate,
  FaProjectDiagram,
  FaRocket,
  FaTimes,
  FaCreditCard,
  FaUser,
  FaMapMarkerAlt,
  FaEnvelope,
  FaPhone,
} from 'react-icons/fa';
import './Pricing.css';
import './PaymentPopup.css'; // Popup için gerekli stilleri içermelidir
import ConsentPopup from './ConsentPopup'; // ConsentPopup bileşenini içe aktarıyoruz
import DistanceSalesAgreementPopup from './DistanceSalesAgreementPopup'; // Mesafeli Satış Sözleşmesi Popup'ı

const Pricing = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isConsentOpen, setIsConsentOpen] = useState(false); // ConsentPopup kontrolü
  const [isAgreementOpen, setIsAgreementOpen] = useState(false); // Mesafeli Satış Sözleşmesi Popup kontrolü

  const pricingData = [
    {
      title: 'Python Plus',
      price: 480,
      lessonCount: 32,
      discountPercentage: 20,
      features: [
        '32 Haftalık temel kursumuz',
        '8 gerçek dünya Projesi',
        'Python Plus Sertifikası',
        'Diğer paketlerimizin temelidir',
      ],
    },
    {
      title: 'Python Pro',
      price: 490,
      lessonCount: 24,
      discountPercentage: 15,
      features: [
        '24 haftalık gelişmiş kursumuz',
        'Ekstra 6 gerçek dünya Projesi',
        'Öğrencinin Python Plus ya da Focus kursunu tamamlamış olması gerekir',
        'Python Pro ve Yapay Zeka Sertifikaları',
      ],
    },
    {
      title: 'Python Focus 1-on-1',
      price: 750,
      lessonCount: 32,
      discountPercentage: 20,
      features: [
        "Birebir özel paket (32 Ders)",
        '8 gerçek dünya Projesi',
        'Python Focus, birebir özel ve kapsamlı bir Python eğitim programıdır.',
        'Python Focus 1-on-1 Sertifikası',
      ],
    },
  ];

  // Ödeme işlemini başlatan fonksiyon
  const handlePayment = async (userData, finalPrice, discountCode) => {
    if (!selectedPlan) return;

    try {
      // Backend'e gönderilecek ödeme verileri
      const paymentData = {
        amount: finalPrice.toFixed(2),
        referenceId: 'UBICRAFT',
        orderDescription:
          selectedPlan.title + (discountCode ? ` ${discountCode}` : ''),
        userName: userData.userName,
        userEmail: userData.userEmail,
        userPhone: userData.userPhone,
        userAddress: userData.userAddress,
      };

      // Backend'e ödeme isteği gönderme
      const response = await fetch('https://backend.ubicraft.org/api/create-payment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(paymentData),
      });

      const result = await response.json();

      if (result.paymentUrl) {
        // Kullanıcıyı Papara ödeme sayfasına yönlendir
        window.location.href = result.paymentUrl;
      } else {
        alert('Ödeme işlemi başlatılamadı. Lütfen tekrar deneyin.');
      }
    } catch (error) {
      console.error('Ödeme işlemi sırasında bir hata oluştu:', error);
      alert('Ödeme işlemi sırasında bir hata oluştu. Lütfen tekrar deneyin.');
    }
  };

  // "Hemen Başla" butonuna tıklandığında çağrılan fonksiyon
  const handleBuyClick = (plan) => {
    setSelectedPlan(plan);
    setIsPopupOpen(true);
  };

  // EnhancedPaymentPopup bileşeni
  const EnhancedPaymentPopup = ({ isOpen, onClose, selectedPlan }) => {
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);
    const [userData, setUserData] = useState({
      userName: '',
      userEmail: '',
      userPhone: '',
      userAddress: '',
    });
    const [discountCode, setDiscountCode] = useState('');
    const [discountedPrice, setDiscountedPrice] = useState(null);
    const [discountError, setDiscountError] = useState('');
    const [isDiscountApplied, setIsDiscountApplied] = useState(false);
    const [isConsentOpen, setIsConsentOpen] = useState(false); // ConsentPopup kontrolü
    const [isAgreementOpen, setIsAgreementOpen] = useState(false); // Mesafeli Satış Sözleşmesi Popup kontrolü

    // selectedPlan kontrolünü burada yapıyoruz
    if (!isOpen || !selectedPlan) return null;

    // selectedPlan artık güvenli bir şekilde kullanılabilir
    const totalPrice = selectedPlan.price * selectedPlan.lessonCount;
    const baseDiscountedPrice =
      totalPrice * (1 - selectedPlan.discountPercentage / 100);

    const finalPrice =
      discountedPrice !== null ? discountedPrice : baseDiscountedPrice;

    // İndirim kodunu uygulama fonksiyonu
    const applyDiscountCode = async () => {
      if (!discountCode) return;

      try {
        const response = await fetch(
          'https://backend.ubicraft.org/api/apply-discount',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              code: discountCode,
              totalPrice: baseDiscountedPrice,
            }),
          }
        );

        const result = await response.json();

        if (response.ok) {
          setDiscountedPrice(result.discountedPrice);
          setIsDiscountApplied(true);
          setDiscountError('');
        } else {
          setDiscountError(result.message || 'Geçersiz indirim kodu');
          setIsDiscountApplied(false);
          setDiscountedPrice(null);
        }
      } catch (error) {
        console.error('İndirim kodu uygulanırken bir hata oluştu:', error);
        setDiscountError('İndirim kodu uygulanırken bir hata oluştu.');
        setIsDiscountApplied(false);
        setDiscountedPrice(null);
      }
    };

    // ConsentPopup açma fonksiyonu
    const handleConsentOpen = (e) => {
      e.preventDefault();
      setIsConsentOpen(true);
    };

    // Mesafeli Satış Sözleşmesi Popup açma fonksiyonu
    const handleAgreementOpen = (e) => {
      e.preventDefault();
      setIsAgreementOpen(true);
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      if (isTermsAccepted) {
        handlePayment(userData, finalPrice, discountCode);
      }
    };

    return (
      <>
        <div className="enhanced-popup-overlay">
          <div className="enhanced-popup-content">
            <div className="enhanced-popup-header">
              <h2>Ödeme Bilgileri</h2>
              <button className="enhanced-close-button" onClick={onClose}>
                <FaTimes />
              </button>
            </div>
            <div className="enhanced-popup-body">
              <div className="enhanced-payment-info">
                <h3>Seçilen Plan: {selectedPlan.title}</h3>
                <p className="enhanced-plan-details">
                  {selectedPlan.lessonCount} Ders, {finalPrice.toFixed(2)} TL
                </p>
                {/* İndirim kodu girişi */}
                <div className="discount-code-section">
                  <label htmlFor="discountCode">İndirim Kodu:</label>
                  <input
                    type="text"
                    id="discountCode"
                    value={discountCode}
                    onChange={(e) => setDiscountCode(e.target.value)}
                    placeholder="İndirim kodunuzu girin"
                  />
                  <button type="button" onClick={applyDiscountCode}>
                    Uygula
                  </button>
                  {discountError && (
                    <p className="discount-error">{discountError}</p>
                  )}
                  {isDiscountApplied && (
                    <p className="discount-success">
                      İndirim kodu uygulandı! Yeni fiyat: {finalPrice.toFixed(2)} TL
                    </p>
                  )}
                </div>
                <h3>Ödeme Prosedürü</h3>
                <ul>
                  <li>
                    Bu sayfadaki bilgiler, eğer ödemeniz başarılı olursa fatura ve sözleşme detaylarını oluşturmak ve size ulaştırmak için istenir. Ödeme başarılı olmadan herhangi bir işlem başlatılmaz.
                  </li>
                  <li>
                    Ödeme, kredi kartına taksit seçenekleri ile güvenli bir şekilde Papara arayüzü üzerinden gerçekleştirilecektir.
                  </li>
                  <li>
                    Ödeme tamamlandıktan sonra fatura ve sözleşmeniz e-posta adresinize gönderilecektir.
                  </li>
                  <li>
                    Herhangi bir sorunuz veya endişeniz varsa, info@ubicraft.org mail adresine +90 (505) 713 17 27 numarasına Whatsapp üzerinden mesaj ile iletebilirsiniz.
                  </li>
                </ul>
              </div>
              <div className="enhanced-parent-info">
                <h3>Veli Bilgileri</h3>
                <form onSubmit={handleSubmit}>
                  <div className="enhanced-form-group">
                    <label htmlFor="name">
                      <FaUser className="enhanced-form-icon" />
                      Ad Soyad:
                    </label>
                    <input
                      type="text"
                      id="name"
                      value={userData.userName}
                      onChange={(e) =>
                        setUserData({ ...userData, userName: e.target.value })
                      }
                      required
                    />
                  </div>
                  <div className="enhanced-form-group">
                    <label htmlFor="email">
                      <FaEnvelope className="enhanced-form-icon" />
                      E-posta:
                    </label>
                    <input
                      type="email"
                      id="email"
                      value={userData.userEmail}
                      onChange={(e) =>
                        setUserData({ ...userData, userEmail: e.target.value })
                      }
                      required
                    />
                  </div>
                  <div className="enhanced-form-group">
                    <label htmlFor="phone">
                      <FaPhone className="enhanced-form-icon" />
                      Telefon:
                    </label>
                    <input
                      type="tel"
                      id="phone"
                      value={userData.userPhone}
                      onChange={(e) =>
                        setUserData({ ...userData, userPhone: e.target.value })
                      }
                      required
                    />
                  </div>
                  <div className="enhanced-form-group">
                    <label htmlFor="address">
                      <FaMapMarkerAlt className="enhanced-form-icon" />
                      Adres:
                    </label>
                    <textarea
                      id="address"
                      value={userData.userAddress}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          userAddress: e.target.value,
                        })
                      }
                      required
                    ></textarea>
                  </div>
                  <div className="enhanced-form-group">
                    <label>
                      <input
                        type="checkbox"
                        checked={isTermsAccepted}
                        onChange={(e) =>
                          setIsTermsAccepted(e.target.checked)
                        }
                        required
                      />
                      {/* Açık rıza ve Aydınlatma metnini tıklanabilir hale getiriyoruz */}
                      <span>
                        {' '}
                        <a href="#" onClick={handleConsentOpen}>
                          Açık rıza ve Aydınlatma metnini
                        </a>
                        ,{' '}
                        <a href="#" onClick={handleAgreementOpen}>
                          Mesafeli satış sözleşmesini
                        </a>{' '}
                        kabul ediyorum.
                      </span>
                    </label>
                  </div>
                  <button type="submit" disabled={!isTermsAccepted}>
                    <FaCreditCard className="enhanced-submit-icon" />
                    Ödeme Adımına Geçin
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* ConsentPopup bileşenini burada render ediyoruz */}
        <ConsentPopup
          isOpen={isConsentOpen}
          onClose={() => setIsConsentOpen(false)}
        />
        {/* Mesafeli Satış Sözleşmesi Popup bileşenini burada render ediyoruz */}
        <DistanceSalesAgreementPopup
          isOpen={isAgreementOpen}
          onClose={() => setIsAgreementOpen(false)}
        />
      </>
    );
  };

  // PricingCard bileşeni
  const PricingCard = ({ data, isPopular, isBestValue }) => {
    const totalPrice = data.price * data.lessonCount;
    const baseDiscountedPrice =
      totalPrice * (1 - data.discountPercentage / 100);
    const discountedPerLessonPrice =
      data.price * (1 - data.discountPercentage / 100);

    return (
      <div
        className={`pricing-card ${isPopular ? 'popular' : ''} ${
          isBestValue ? 'best-value' : ''
        }`}
      >
        {isPopular && <div className="popular-label">En Popüler</div>}
        {isBestValue && (
          <div className="best-value-label">En İyi Fiyat</div>
        )}
        <h3 className="card-title">{data.title}</h3>
        <div className="price-container">
          <div className="original-per-lesson-price">
            <span className="original-price-text">{data.price} TL</span>
            <span className="per-lesson"> / Ders</span>
          </div>
          <div className="discounted-per-lesson-price">
            {discountedPerLessonPrice.toFixed(0)} TL{' '}
            <span className="per-lesson"> / Ders</span>
          </div>
        </div>
        <div className="total-price">
          Toplam: <span className="original-price">{totalPrice} TL</span>
          <span className="discounted-price">
            {baseDiscountedPrice.toFixed(0)} TL
          </span>
        </div>
        <div className="discount-badge">
          %{data.discountPercentage} İndirim
        </div>
        <p className="lessons-count">{data.lessonCount} Ders</p>
        <ul className="features">
          {data.features.map((feature, index) => (
            <li key={index}>
              {[
                <FaCheck key="check" />,
                <FaStar key="star" />,
                <FaCertificate key="certificate" />,
                <FaProjectDiagram key="project" />,
                <FaRocket key="rocket" />,
              ][index % 5]}
              {feature}
            </li>
          ))}
        </ul>
        <button
          className="buy-button"
          onClick={() => handleBuyClick(data)}
        >
          Hemen Başla
        </button>
      </div>
    );
  };

  return (
    <div className="pricing-container">
      <h2 className="pricing-title">Fiyatlandırma Planları</h2>
      <p className="pricing-subtitle">
        Hedeflerinize uygun programı seçin ve Python yolculuğunuza başlayın
      </p>
      <div className="pricing-cards">
        {pricingData.map((data, index) => (
          <PricingCard
            key={index}
            data={data}
            isPopular={index === 0}
            isBestValue={index === 1}
          />
        ))}
      </div>
      {/* Popup'ı burada render ediyoruz */}
      <EnhancedPaymentPopup
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        selectedPlan={selectedPlan}
      />
    </div>
  );
};

export default Pricing;

import React, { useState } from 'react';
import {
  FaLaptopCode,
  FaRobot,
  FaUserGraduate,
  FaRocket,
  FaBrain,
  FaLightbulb,
  FaChartLine,
  FaProjectDiagram,
  FaUsers,
} from 'react-icons/fa';
import './Company.css';
import TermsAndConditionsPopup from './TermsAndConditionsPopup';
import PrivacyPolicyPopup from './PrivacyPolicyPopup';

const CourseCard = ({ title, description, features, certificate, icon: Icon }) => (
  <div className="interactive-kids-coding-course-card">
    <div className="interactive-kids-coding-course-icon">
      <Icon />
    </div>
    <h3 className="interactive-kids-coding-course-title">{title}</h3>
    <p className="interactive-kids-coding-course-description">{description}</p>
    <ul className="interactive-kids-coding-course-features">
      {features.map((feature, index) => (
        <li key={index} className="interactive-kids-coding-course-feature-item">{feature}</li>
      ))}
    </ul>
    <div className="interactive-kids-coding-course-certificate">
      <FaUserGraduate />
      <p>{certificate}</p>
    </div>
  </div>
);

const InteractiveKidsCodingCourses = () => {
  const [selectedCourse, setSelectedCourse] = useState(0);
  const [isCheckedTerms, setIsCheckedTerms] = useState(false);
  const [isCheckedPrivacy, setIsCheckedPrivacy] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const coursesData = [
    {
      title: 'Python Plus',
      description: "Çocuğunuzun kodlama dünyasına ilk adımı! Eğlenceli projelerle Python'un temellerini öğrenin.",
      features: [
        'Temel programlama kavramları',
        'Eğlenceli oyun projeleri',
        'Görsel programlama araçları',
        '8-17 yaş grubu için uygun',
      ],
      certificate: 'Python Plus Sertifikası',
      icon: FaLaptopCode,
    },
    {
      title: 'Python Pro',
      description: 'Kodlama becerilerini bir üst seviyeye taşı! Daha karmaşık projeler ve algoritma çözümleri.',
      features: [
        'İleri düzey Python kavramları',
        'Yapay zeka ve makine öğrenimi temelleri',
        'Web geliştirme temelleri',
        '12-17 yaş grubu için uygun',
      ],
      certificate: 'Python Pro Sertifikası',
      icon: FaRobot,
    },
    {
      title: 'Python Focus 1-on-1',
      description: "Çocuğunuzun kodlama dünyasına ilk adımı! Eğlenceli projelerle Python'un temellerini öğrenin.",
      features: [
        'Temel programlama kavramları (Birebir Dersler)',
        'Eğlenceli oyun projeleri',
        'Görsel programlama araçları',
        '8-17 yaş grubu için uygun',
      ],
      certificate: 'Python Plus Sertifikası',
      icon: FaLaptopCode,
    },
  ];

  const skills = [
    { name: 'Algoritmik düşünme', icon: FaBrain },
    { name: 'Yaratıcı problem çözme', icon: FaLightbulb },
    { name: 'Mantıksal akıl yürütme', icon: FaChartLine },
    { name: 'Proje yönetimi', icon: FaProjectDiagram },
    { name: 'Takım çalışması', icon: FaUsers },
  ];

  const handleButtonClick = (e) => {
    if (!isCheckedTerms || !isCheckedPrivacy) {
      e.preventDefault();
      setErrorMessage('Lütfen tüm sözleşmeleri kabul ediniz.');
    } else {
      setErrorMessage('');
      window.location.href = 'https://form.jotform.com/243057905406961';
    }
  };

  return (
    <div className="interactive-kids-coding-container">
      <h2 className="interactive-kids-coding-title">Geleceğin kapılarını aralamaya ne dersiniz?</h2>
      <div className="interactive-kids-coding-content">
        <div className="interactive-kids-coding-course-section">
          <div className="interactive-kids-coding-course-tabs">
            {coursesData.map((course, index) => (
              <button
                key={index}
                className={`interactive-kids-coding-course-tab ${selectedCourse === index ? 'interactive-kids-coding-course-tab-active' : ''}`}
                onClick={() => setSelectedCourse(index)}
              >
                {course.title}
              </button>
            ))}
          </div>
          <CourseCard {...coursesData[selectedCourse]} />
        </div>
        <div className="interactive-kids-coding-promo-section">
          <h3 className="interactive-kids-coding-promo-title">Kodlama ile Geleceğe Yolculuk!</h3>
          <p className="interactive-kids-coding-promo-description">
            Çocuğunuzun hayal gücünü ve problem çözme becerilerini geliştirin. Python ile kodlama
            öğrenerek teknoloji dünyasında öne çıkmasını sağlayın!
          </p>
          <h4 className="interactive-kids-coding-skills-title">Kazanılacak Beceriler:</h4>
          <ul className="interactive-kids-coding-skills-list">
            {skills.map((skill, index) => (
              <li key={index} className="interactive-kids-coding-skill-item">
                <skill.icon className="interactive-kids-coding-skill-icon" />
                {skill.name}
              </li>
            ))}
          </ul>
          <div className="interactive-kids-coding-terms-conditions">
            <div className="interactive-kids-coding-terms-item">
              <input
                type="checkbox"
                id="terms-checkbox"
                checked={isCheckedTerms}
                onChange={() => {
                  setIsCheckedTerms(!isCheckedTerms);
                  setErrorMessage('');
                }}
                className="interactive-kids-coding-terms-checkbox"
              />
              <label htmlFor="terms-checkbox" className="interactive-kids-coding-terms-label">
                <span className="interactive-kids-coding-terms-link" onClick={() => setShowTerms(true)}>Şartlar ve Koşulları</span> kabul ediyorum.
              </label>
            </div>
            <div className="interactive-kids-coding-terms-item">
              <input
                type="checkbox"
                id="privacy-checkbox"
                checked={isCheckedPrivacy}
                onChange={() => {
                  setIsCheckedPrivacy(!isCheckedPrivacy);
                  setErrorMessage('');
                }}
                className="interactive-kids-coding-terms-checkbox"
              />
              <label htmlFor="privacy-checkbox" className="interactive-kids-coding-terms-label">
                <span className="interactive-kids-coding-terms-link" onClick={() => setShowPrivacy(true)}>Gizlilik Sözleşmesi KVKK metnini</span> kabul ediyorum.
              </label>
            </div>
          </div>
          {errorMessage && <p className="interactive-kids-coding-error-message">{errorMessage}</p>}
          <button
            className={`interactive-kids-coding-free-trial-button ${isCheckedTerms && isCheckedPrivacy ? '' : 'interactive-kids-coding-free-trial-button-disabled'}`}
            onClick={handleButtonClick}
          >
            <FaRocket className="interactive-kids-coding-button-icon" />
            Ücretsiz Deneme Dersi Al
          </button>
        </div>
      </div>

      <TermsAndConditionsPopup isOpen={showTerms} onClose={() => setShowTerms(false)} />
      <PrivacyPolicyPopup isOpen={showPrivacy} onClose={() => setShowPrivacy(false)} />
    </div>
  );
};

export default InteractiveKidsCodingCourses;

import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ubicraftLogo from "./courseimages/ubicraft.png";
import { FaHome, FaBook, FaCreditCard, FaUser, FaQuestionCircle, FaChevronDown, FaChevronUp } from "react-icons/fa";
import "./NavBar.css";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [showCourseDropdown, setShowCourseDropdown] = useState(false);
  const [showMobileCourseDropdown, setShowMobileCourseDropdown] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const courses = [
    { name: "Python Focus 1-on-1", path: "/python-focus" },
    { name: "Python Pro", path: "/python-pro" },
    { name: "Python Plus", path: "/python-plus" }
  ];

  return (
    <nav className="navbar">
      {/* Desktop Menu */}
      <div className="flex items-center w-full justify-between">
        <button onClick={() => { navigate("/"); }} className="ml-0 md:ml-6">
          <img src={ubicraftLogo} alt="ubicraft" className="h-12" />
        </button>
        <div className="desktop-menu">
          <a href="/" className={`nav-link ${location.pathname === '/' ? 'active' : ''}`}>
            Ana Sayfa
          </a>
          
          <div
            className="courses-dropdown"
            onMouseEnter={() => setShowCourseDropdown(true)}
            onMouseLeave={() => setShowCourseDropdown(false)}
          >
            <button className={`nav-link courses-btn ${location.pathname.includes('/python') ? 'active' : ''}`}>
              Kurslar <FaChevronDown className="inline-block ml-1" />
            </button>
            
            {showCourseDropdown && (
              <div className="dropdown-menu">
                {courses.map((course) => (
                  <a
                    key={course.path}
                    href={course.path}
                    className={`dropdown-item ${location.pathname === course.path ? 'active' : ''}`}
                  >
                    {course.name}
                  </a>
                ))}
              </div>
            )}
          </div>

          <a href="/pricing" className={`nav-link ${location.pathname === '/pricing' ? 'active' : ''}`}>
            Fiyatlandırma
          </a>
          <a href="/login" className={`nav-link ${location.pathname === '/login' ? 'active' : ''}`}>
            Giriş Yap
          </a>
          <a href="/faqs" className={`nav-link ${location.pathname === '/faqs' ? 'active' : ''}`}>
            S.S.S.
          </a>
          <button
            onClick={() => { navigate("/pricing"); }}
            className="primary-button"
          >
            Planlarımızı Keşfedin
          </button>
        </div>
      </div>
      
      {/* Mobile Menu */}
      <div className="mobile-navigation-container">
        {/* Mobile Course Dropdown */}
        <div className={`mobile-course-dropdown ${showMobileCourseDropdown ? 'show' : ''}`}>
          <div className="mobile-dropdown-header">
            <span>Kurslar</span>
            <button 
              className="close-button"
              onClick={() => setShowMobileCourseDropdown(false)}
            >
              <FaChevronDown />
            </button>
          </div>
          <div className="mobile-dropdown-content">
            {courses.map((course) => (
              <div
                key={course.path}
                className={`mobile-course-item ${location.pathname === course.path ? 'active' : ''}`}
                onClick={() => {
                  navigate(course.path);
                  setShowMobileCourseDropdown(false);
                }}
              >
                {course.name}
              </div>
            ))}
          </div>
        </div>

        {/* Mobile Bottom Navigation */}
        <div className="mobile-menu-bottom">
          <ul>
            <li onClick={() => navigate("/")} className={location.pathname === '/' ? 'active' : ''}>
              <FaHome />
              <span>Ana Sayfa</span>
            </li>
            <li 
              onClick={() => setShowMobileCourseDropdown(!showMobileCourseDropdown)}
              className={`courses-tab ${location.pathname.includes('/python') ? 'active' : ''}`}
            >
              <FaBook />
              <span>Kurslar</span>
              {showMobileCourseDropdown ? <FaChevronUp className="chevron-icon" /> : <FaChevronDown className="chevron-icon" />}
            </li>
            <li onClick={() => navigate("/pricing")} className={location.pathname === '/pricing' ? 'active' : ''}>
              <FaCreditCard />
              <span>Fiyatlandırma</span>
            </li>
            <li onClick={() => navigate("/faqs")} className={location.pathname === '/faqs' ? 'active' : ''}>
              <FaQuestionCircle />
              <span>S.S.S.</span>
            </li>
            <li onClick={() => navigate("/login")} className={location.pathname === '/login' ? 'active' : ''}>
              <FaUser />
              <span>Giriş Yap</span>
            </li>
          </ul>
        </div>

        {/* Overlay for mobile dropdown */}
        {showMobileCourseDropdown && (
          <div 
            className="mobile-dropdown-overlay"
            onClick={() => setShowMobileCourseDropdown(false)}
          />
        )}
      </div>
    </nav>
  );
};

export default Navbar;
import React, { useState } from 'react';
import { Clock, Users, Award, Star, X } from 'lucide-react';
import './PythonStart.css';

const AnimatedBadge = ({ children, className }) => (
  <div className={`pci-animated-badge ${className}`}>
    <div className="pci-badge-content">
      <div className="pci-animated-circle-1"></div>
      <div className="pci-animated-circle-2"></div>
      <div className="pci-animated-dot"></div>
      <span className="pci-badge-text">{children}</span>
    </div>
  </div>
);

const PythonCourseIntro = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  return (
    <div className="pci-course-container">
      <div className="pci-wrapper">
        <div className="pci-course-card">
          <div className="pci-course-content">
            <div className="pci-content-left">
              {/* Mevcut içerik aynı kalıyor */}
              <div className="pci-badges-container">
                <AnimatedBadge className="pci-badge-purple">
                  ONLİNE EĞİTİM
                </AnimatedBadge>
                <AnimatedBadge className="pci-badge-green">
                  YENİ BAŞLAYANLAR
                </AnimatedBadge>
              </div>
              
              <h1 className="pci-course-title">Python Focus 1-on-1 Kursu</h1>
              
              <p className="pci-course-description">
                Programlamaya yeni başlayanlar için özel olarak tasarlanmış, 
                sıfırdan Python öğreten, hızlı bir kodlamaya giriş programı. 
                Temel programlama mantığından dijital çizimlere geniş bir yelpazede kadar 
                pratik örneklerle Python'ı eğlenceli ve interaktif bir şekilde öğrenin. Dersler tamamen birebirdir.
              </p>

              <div className="pci-students-info">
                <Users className="pci-icon" />
                <span>
                  Son <strong>21 </strong> kontenjan kaldı!
                </span>
              </div>

              <div className="pci-features-grid">
                <div className="pci-feature-card">
                  <Star className="pci-feature-icon pci-yellow" />
                  <div>
                    <div className="pci-feature-title">Başlangıç Seviyesi</div>
                    <div className="pci-feature-subtitle">Ön bilgi gerekmiyor</div>
                  </div>
                </div>
                <div className="pci-feature-card">
                  <Award className="pci-feature-icon pci-purple" />
                  <div>
                    <div className="pci-feature-title">Sertifika</div>
                    <div className="pci-feature-subtitle">Bitirme sertifikası</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="pci-pricing-container">
              <div className="pci-pricing-card">
                <div className="pci-pricing-header">
                  <h2>Kurs Detayları</h2>
                </div>
                <div className="pci-pricing-content">
                  <div className="pci-price-row">
                    <span>Eğitim Ücreti</span>
                    <span className="pci-price">19200 TL</span>
                  </div>
                  
                  <div className="pci-detail-row">
                    <span>Toplam Süre</span>
                    <div className="pci-time-info">
                      <Clock className="pci-icon" />
                      <span>48 Saat / 8 Ay</span>
                    </div>
                  </div>

                  <div className="pci-detail-row">
                    <span>Sertifika</span>
                    <span>Var</span>
                  </div>

                  <a
                    href="https://ubicraft.org/pricing"
                    className="pci-primary-button"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Hemen Başla
                  </a>
                  
                  <button
                    onClick={() => setIsPopupOpen(true)}
                    className="pci-secondary-button"
                  >
                    Detaylı Bilgi Al
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Popup */}
      {isPopupOpen && (
        <div className="pci-popup-overlay">
          <div className="pci-popup">
            <button className="pci-popup-close" onClick={() => setIsPopupOpen(false)}>
              <X size={24} />
            </button>
            <h2 className="pci-popup-title">İletişim Seçenekleri</h2>
            
            <div className="pci-popup-content">
              <div className="pci-popup-option">
                <h3>WhatsApp ile İletişim</h3>
                <p>Anlık mesajlaşma ile hızlıca sorularınızı yanıtlayalım. Size özel ders planı ve fiyatlandırma hakkında bilgi verelim.</p>
                <a 
                  href="https://api.whatsapp.com/send/?phone=905057131727&text&type=phone_number&app_absent=0"
                  className="pci-popup-button pci-whatsapp"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  WhatsApp'tan Ulaş
                </a>
              </div>

              <div className="pci-popup-option">
                <h3>İletişim Formu</h3>
                <p>Size uygun bir zamanda geri dönüş yapalım. Form üzerinden bilgilerinizi bırakın, en kısa sürede sizinle iletişime geçelim.</p>
                <a 
                  href="https://form.jotform.com/243057905406961"
                  className="pci-popup-button pci-form"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Bize Ulaşın
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PythonCourseIntro;
import React, { useState } from 'react';
import './TermsAndConditionsPopup.css';

const TermsAndConditionsPopup = ({ isOpen, onClose }) => {
  const [activeSection, setActiveSection] = useState('introduction');

  const sections = [
    { id: 'introduction', title: 'Giriş' },
    { id: 'serviceDescription', title: 'Hizmet Tanımı' },
    { id: 'userAccounts', title: 'Kullanıcı Hesapları' },
    { id: 'personalData', title: 'Kişisel Bilgiler ve Veri Güvenliği' },
    { id: 'intellectualProperty', title: 'Fikri Mülkiyet Hakları' },
    { id: 'fees', title: 'Ücretler ve İade Politikası' },
    { id: 'serviceContinuity', title: 'Hizmet Sürekliliği' },
    { id: 'userObligations', title: 'Kullanıcı Yükümlülükleri' },
    { id: 'misuse', title: 'Kötüye Kullanım ve Yaptırımlar' },
    { id: 'postponement', title: 'Derslerin Ertelenmesi ve Dondurulması' },
    { id: 'disclaimer', title: 'Sorumluluk Reddi' },
    { id: 'changes', title: 'Değişiklikler' },
    { id: 'legalDisputes', title: 'Yasal Uyuşmazlıklar' },
    { id: 'contactInfo', title: 'İletişim Bilgileri' },
  ];

  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2>Ubicraft Platform Şartlar ve Koşullar Sözleşmesi</h2>
        <div className="popup-body">
          <div className="section-nav">
            {sections.map((section) => (
              <button
                key={section.id}
                className={activeSection === section.id ? 'active' : ''}
                onClick={() => setActiveSection(section.id)}
              >
                {section.title}
              </button>
            ))}
          </div>
          <div className="section-content">
            {activeSection === 'introduction' && (
              <div>
                <h3>Giriş</h3>
                <p>Bu Şartlar ve Koşullar, Ubicraft Platform tarafından sağlanan hizmetlerin kullanımını düzenler. Ubicraft Platform, 8-17 yaş arası çocuklara online yazılım eğitimi veren bir platformdur. Bu sözleşme, Python Focus 1-on-1, Python Plus ve Python Pro kurslarını kapsamaktadır. Platformumuzu kullanmadan önce aşağıdaki şartları dikkatlice okuyun. Platforma erişim sağlayarak bu şartları kabul etmiş olursunuz.</p>
              </div>
            )}
            {activeSection === 'serviceDescription' && (
              <div>
                <h3>Hizmet Tanımı</h3>
                <p>Ubicraft Platformu, aşağıdaki eğitim hizmetlerini sunmaktadır:</p>
                <ul>
                  <li>Python Plus: Python Temellerine yoğunlaşan bu kurs, oyun geliştirme ile sona erer. 1 buçuk saatlik 32 online canlı ders içerir.</li>
                  <li>Python Pro: Yapay zeka ve web geliştirmeye odaklanır. 1 buçuk saatlik 24 canlı ders içerir.</li>
                  <li>Python Focus 1-on-1: Python'a sağlam ve eğlenceli bir giriş yapmayı amaçlar. 1 buçuk saatlik 32 canlı ders içerir. Dersler birebirdir.</li>
                </ul>
              </div>
            )}
            {activeSection === 'userAccounts' && (
              <div>
                <h3>Kullanıcı Hesapları</h3>
                <p>Kullanıcı hesapları, platform tarafından oluşturulacak olup, kullanıcılar kendi hesaplarını oluşturamazlar. Kurs sürecini tamamlayan kullanıcıların hesaplarına erişimi kapatılacaktır. Hesap kapatma talepleri için kullanıcılar, info@ubicraft.com adresine e-posta göndererek talepte bulunabilirler. Bu talep doğrultusunda veritabanımızdan bilgiler silinecektir.</p>
              </div>
            )}
            {activeSection === 'personalData' && (
              <div>
                <h3>Kişisel Bilgiler ve Veri Güvenliği</h3>
                <p>Ubicraft Platform, öğrencilerin velilerinden isim, adres ve telefon numarası gibi bilgileri öğrenci deneyiminin geliştirilmesi ve iletişim amacıyla toplar. Bu bilgiler güvenli sunucularda saklanır ve yalnızca bireysel kullanıcıya gösterilir.</p>
                <ul>
                  <li>Verilerin Saklanma Süresi: Kurs bitiminden itibaren kullanıcı hesaplarına ait tüm kişisel veriler 1 hafta içerisinde sistemden silinir.</li>
                  <li>Veri Güvenliği: Öğrenci hesaplarına erişimde şifrelenmiş veriler kullanılır ve SSL sertifikaları ile veri güvenliği sağlanır.</li>
                  <li>Ebeveyn Onayı: Bu platformu kullanmak için öğrencinin velisinin izni gereklidir. Velilerin onayı olmadan öğrenci hesapları oluşturulamaz.</li>
                  <li>Üçüncü Taraf Hizmetler: Platform, Zoom, Trinket ve Google Colab hesaplarını öğrencilere tamamen ücretsiz olarak sunar.</li>
                  <li>Kullanıcı Desteği: Her kullanıcıya özel bir müşteri temsilcisi atanır. Herhangi bir sorun durumunda kullanıcılar bu temsilciler ile iletişime geçebilirler.</li>
                </ul>
              </div>
            )}
            {activeSection === 'intellectualProperty' && (
              <div>
                <h3>Fikri Mülkiyet Hakları</h3>
                <p>Platformda yer alan tüm içerik ve materyaller Ubicraft Platforma aittir. Kullanıcılar, bu içerikleri yalnızca platformun belirlediği kurallar çerçevesinde kullanabilir.</p>
              </div>
            )}
            {activeSection === 'fees' && (
              <div>
                <h3>Ücretler ve İade Politikası</h3>
                <p>Kurs ücretleri:</p>
                <ul>
                  <li>Python Plus Kursu: 15.360 TL başlangıç fiyatı</li>
                  <li>Python Pro Kursu: 11.760 TL başlangıç fiyatı</li>
                  <li>Python Focus 1 Kursu: 24.000 TL başlangıç fiyatı</li>
                </ul>
                <p>Fiyatlar, kişiye özel indirimler ve kampanyalarla değişiklik gösterebilir.</p>
                <p>İade Koşulları:</p>
                <ul>
                  <li>Kursun ilk 1 ayında koşulsuz şartsız iade yapılır.</li>
                  <li>Sonraki 1 ay içinde, öğrencinin kullanmadığı derslerin bedeli iade edilir.</li>
                  <li>İade talepleri, info@ubicraft.com adresine e-posta atarak veya müşteri temsilcileriyle iletişime geçilerek yapılabilir.</li>
                  <li>İade talepleri alındıktan sonra 3 iş günü içinde işlenir. Tutarın hesaba yatması, bankalar arası değişiklik gösterebilir.</li>
                </ul>
              </div>
            )}
            {activeSection === 'serviceContinuity' && (
              <div>
                <h3>Hizmet Sürekliliği</h3>
                <p>Hizmetler, dini bayramlar ve resmi tatiller dışında kesintisiz olarak devam eder. Eğer bir öğretmen derse katılamazsa, yerine başka bir öğretmen atanır veya öğrenci ve öğretmen mutabık kalırsa ders başka bir tarihe ertelenebilir.</p>
              </div>
            )}
            {activeSection === 'userObligations' && (
              <div>
                <h3>Kullanıcı Yükümlülükleri</h3>
                <p>Kullanıcılar platformu yalnızca kişisel eğitim amaçları için kullanabilir. Platformun içeriği izinsiz olarak kopyalanamaz, satılamaz veya ticari amaçla kullanılamaz. Kullanıcılar, diğer kullanıcıların hesaplarına izinsiz erişim sağlamamalıdır.</p>
              </div>
            )}
            {activeSection === 'misuse' && (
              <div>
                <h3>Kötüye Kullanım ve Yaptırımlar</h3>
                <p>Kullanıcılar, platformun herhangi bir özelliğini kötüye kullanamaz. Kötüye kullanım durumunda, kullanıcı hesapları askıya alınabilir veya tamamen kapatılabilir. Bu durum, kullanıcıya hiçbir geri ödeme yapılmaması ile sonuçlanabilir.</p>
              </div>
            )}
            {activeSection === 'postponement' && (
              <div>
                <h3>Derslerin Ertelenmesi ve Dondurulması</h3>
                <ul>
                  <li>Ders Erteleme: Öğrenciler, dersleri öğretmen ile mutabık kalarak erteleyebilirler.</li>
                  <li>Kurs Dondurma: Kurs dondurma seçeneği, en fazla 8 ay süre ile yapılabilir.</li>
                </ul>
              </div>
            )}
            {activeSection === 'disclaimer' && (
              <div>
                <h3>Sorumluluk Reddi</h3>
                <p>Ubicraft Platform, sağlanan hizmetlerin kesintisiz, hatasız veya tamamen güvenli olduğuna dair garanti vermez. Platformun kullanımı sırasında doğabilecek zararlardan sorumlu tutulamaz.</p>
              </div>
            )}
            {activeSection === 'changes' && (
              <div>
                <h3>Değişiklikler</h3>
                <p>Ubicraft, bu şartları ve koşulları herhangi bir zamanda değiştirme hakkını saklı tutar. Değişiklikler, kullanıcıya platform üzerinden duyurulacak ve kullanıcıların bu değişiklikleri kabul etmesi beklenecektir.</p>
              </div>
            )}
            {activeSection === 'legalDisputes' && (
              <div>
                <h3>Yasal Uyuşmazlıklar</h3>
                <p>Bu sözleşme, Türkiye Cumhuriyeti yasalarına tabidir. Uyuşmazlık durumunda Gaziantep mahkemeleri yetkili olacaktır.</p>
              </div>
            )}
            {activeSection === 'contactInfo' && (
              <div>
                <h3>İletişim Bilgileri</h3>
                <p>Adres: Bülbüzade Mah. 136019. Sok. No:20 İç Kapı No:17 Şahinbey/Gaziantep</p>
                <p>E-posta: info@ubicraft.com</p>
                <p>Telefon: +90 (505) 713 17 27</p>
              </div>
            )}
          </div>
        </div>
        <button className="close-button" onClick={onClose}>X</button>
      </div>
    </div>
  );
};

export default TermsAndConditionsPopup;